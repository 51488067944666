var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddRoleSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-add-role-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("Add role")))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"infoRole"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.addRole($event)}}},[_c('b-form-group',{attrs:{"label-for":"codename","label":_vm.$t('Codename')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Codename'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"codename","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Codename')},model:{value:(_vm.codename),callback:function ($$v) {_vm.codename=$$v},expression:"codename"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"name","label":_vm.$t('Name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":_vm.$t('Permissions')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Permissions'),"label-for":"permissions","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"permissions","options":_vm.permissions,"multiple":"","label":"name"},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("Add")))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }