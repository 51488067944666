<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Edit role") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="infoRole">
        <b-form class="p-2" @submit.prevent="addRole">
          <b-form-group label-for="codename" :label="$t('Codename')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Codename')"
              rules="required"
            >
              <b-form-input
                id="codename"
                v-model="codename"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Codename')"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="name" :label="$t('Name')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Name')"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!----<validation-provider #default="{ errors }" :name="$t('Permissions')">
            <b-form-group
              :label="$t('Permissions')"
              label-for="permissions"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="permissions"
                v-model="selectedPermissions"
                :options="permissions"
                multiple
                label="name"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>-->

          <b-button
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            >{{ $t("Edit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditRoleSidebarActive",
    event: "update:is-edit-role-sidebar-active",
  },
  props: {
    isEditRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    role: {
      type: Object,
      required: true,
    },
  },
  async beforeCreate() {
    try {
      const res = await instance.get("/permissions/all/");
      this.permissions = res.data;
    } catch (err) {
      const error = err.response
        ? Object.values(err.response.data)[0][0]
        : err.message;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: error ?? this.$t("An error has occurred. Please try again"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  data() {
    return {
      required,
      codename: this.role.codename,
      name: this.role.name,
      selectedPermissions: this.role.permissions,
      permissions: [],
    };
  },
  methods: {
    async addRole() {
      const success = await this.$refs.infoRole.validate();
      if (!success) return;

      try {
        await instance.put(`/roles/${this.role.id}`, {
          codename: this.codename,
          name: this.name,
          permissions: this.selectedPermissions,
        });

        this.$emit("refetch-data");
        this.$emit("update:is-edit-role-sidebar-active", false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("It has been updated successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
